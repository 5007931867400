<template>
  <div class="interviewsFilter">
    <a class="wrp-btn-ctx-menu-button" @click="addQuery({ interview: 'today' }), filterInfo('Entrevistas de hoy: $total')" href="#">
      <iconic name="clock" />
      <span>Entrevistas de hoy</span>
    </a>
    <a class="wrp-btn-ctx-menu-button --inputs" href="#">
      <iconic name="calendarCheck" />
      <div w100 tleft class="flex-between flex-center">
        <span w50>Entrevistas de</span>
        <InputMultiEvent
          tend
          type="date"
          :value="$global.toInputDate(this.$route.query.interview !== 'today' ? this.$route.query.interview : null)"
          @event="
            addQuery($event.srcElement.value ? { interview: $event.srcElement.value } : null),
              $event.srcElement.value ? filterInfo(`Entrevistas del ${$global.dateLocaleFormat($route.query.interview)}: $total`) : null
          "
        />
      </div>
    </a>
    <a class="wrp-btn-ctx-menu-button" @click="addQuery({ interview: 'void' }), filterInfo('Entrevistas sin agendar: $total')" href="#">
      <iconic name="calendar_days" />
      <span>Entrevistas sin agendar</span>
    </a>
    <a class="wrp-btn-ctx-menu-button" @click="addQuery({ interview: 'missing' }), filterInfo('Entrevistas que no se presentaron: $total')" href="#">
      <iconic name="calendar_cancel" />
      <span>No se presentó a la entrevista</span>
    </a>
  </div>
</template>

<script>
import InputMultiEvent from "../inputs/InputMultiEvent";
export default {
  components: { InputMultiEvent },
  methods: {
    filterInfo: function (info) {
      this.$root.$emit("filterInfo", info);
    },
  },
};
</script>
