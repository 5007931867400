<script>
import store from "../../store/index.js";
import GalleryVisor from "../web/GalleryVisor";
export default {
  components: { GalleryVisor },
  data: () => {
    return {
      visor: false,
      link: null,
      locale: null,
    };
  },
  methods: {
    videoView: async function (params) {
      const mbody = document.querySelector("body");
      if (params.action == "open") {
        mbody.classList.add("onmodal");
        this.link = params.link;
        this.visor = true;
      } else if (params.action == "close") {
        this.visor = null;
        mbody.classList.remove("onmodal");
      }
    },
    getdataLang: async function () {
      this.locale = await store.getters["localeLang"];
    },
  },
  computed: {
    getAllData: async function () {
      await this.getdataLang();
    },
  },
};
</script>

<template>
  <div class="video-section modal" id="watch" v-bind="getAllData" v-if="visor">
    <div class="video animated fadeInDown">
      <header>
        <a @click="videoView({ action: 'close' })"><i class="fa fa-times video__icon"></i></a>
      </header>
      <div>
        <video class="video-section-video" width="100%" controls autoplay preload="auto">
          <track label="Español" kind="captions" srclang="en">
          <source :src="link" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.video-section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  height: 100vh;
  .video {
    margin: auto;
    max-width: 90%;
    header {
      text-align: right;
      font-size: 160%;
      padding: $mpadding 0;
    }
    &__icon {
    cursor: pointer;
    }
  }
  @media (min-width: $tablet_width) {
    .video {
      max-width: 50%;
    }
  }
  &-video {
    height: auto;
    max-height: 90vh;
  }
}
</style>
