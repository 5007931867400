<template>
  <div class="textarea-rich">
    <div class="textarea-rich-box" :placeholder="placeholder" contenteditable="true" :name="name" v-html="content" @paste.prevent="onPaste"></div>
  </div>
</template>

<script>
export default {
  props: {
    name: null,
    placeholder: null,
    content: null,
  },
  methods: {
    onPaste: function (e) {
      e.preventDefault();
      const text = (e.clipboardData || window.clipboardData).getData("text/plain");
      const formattedText = text.replace(/\n/g, "<br>").replace(/\t/g, "&emsp;").replace(/ /g, "&nbsp;");
      document.execCommand("insertHTML", false, formattedText);
    },
  },
};
</script>

<style lang="scss">
.textarea-rich {
  width: 100%;
  word-wrap: break-word;
  &-box {
    max-width: 550px;
    min-height: 70px;
    padding: $mpadding/2;
    border-radius: $mradius/2;
    border: solid 1px $alto;
    font-size: 100%;
    font-family: $primary_font;
    margin-top: 5px;
    line-height: normal;
    background: $white;
    &:empty::before {
      content: attr(placeholder);
      opacity: 0.75;
    }
  }
}
</style>
