<template>
  <div class="section-models-posts-header" noprint>
    <div class="section-models-posts-header-filter">
      <div class="section-models-posts-header-filter-options">
        <div class="item">
          <button v-html="`Registros <b>${total}</b>`"></button>
        </div>
        <div class="item" @click="$refs.myDatePicker.togglePicker()">
          <button>
            <span>Fecha: </span>
            <span v-if="$route.query.dateFrom">{{ formatDate($route.query.dateFrom) }} - {{ formatDate($route.query.dateTo) }} </span>
          </button>
        </div>
        <div class="item" v-if="roleUser === 'superadmin'">
          <select name="city" :key="keyFilter" @change="setFilter">
            <option value="all">Todas las ciudades</option>
            <option value="medellin">Medellín</option>
            <option value="pereira">Pereira</option>
            <option value="cucuta">Cúcuta</option>
            <option value="bogota">Bogotá</option>
            <option value="other">Otras ciudades</option>
          </select>
        </div>
        <div class="item" v-if="filter === 'all' || filter === 'approved'">
          <select name="interview" :key="keyFilter" @change="selectInterview($event), setFilter($event)">
            <option value="all">Todas las entrevistas</option>
            <option value="void">Sin agendar</option>
            <option value="missing">No se presentó</option>
            <option value="success">Realizadas</option>
            <option value="contacted">Contactadas</option>
            <option value="scheduled">Agendadas</option>
          </select>
        </div>
        <div class="item" v-if="filter === 'all' || filter === 'approved'">
          <select name="mysort" :key="keyFilter" @change="setFilter($event)">
            <option value="" disabled selected>Ordenar</option>
            <option value="interview_asc">Ascendente</option>
            <option value="interview_desc">Descendente</option>
          </select>
        </div>
        <div class="item" v-if="filter === 'all'">
          <select name="status" :key="keyFilter" v-model="statusModel" @change="setFilter">
            <option v-for="(status, id) in getOptionStatus" :key="id" :value="status.value">{{ status.text }}</option>
          </select>
          <div class="item-sub" v-if="this.$route.query.status === 'deserter'">
            <div class="row">
              <span class="check">
                <input @change="setCheck" id="photoSectionFilter" class="mycheck" name="photoSection" type="checkbox" :checked="this.$route.query.photoSection === 'on' ? true : false" />
              </span>
              <label for="photoSectionFilter">Sesión fotográfica</label>
            </div>
            <div class="row">
              <span class="check">
                <input @change="setCheck" id="singContract" class="mycheck" name="singContract" type="checkbox" :checked="this.$route.query.singContract === 'on' ? true : false" />
              </span>
              <label for="singContract">Firma contrato</label>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label" v-html="` Edad : `"></div>
          <div class="slider"><Slider v-model="ageRange" @change="changeAge" /></div>
          <div class="option">
            <a class="apply" @click="handleAge"><i class="fa fa-check"></i></a>
          </div>
        </div>
        <label for="priority-infoFilter" class="item" @change="handleCheck($event)">
          <div role="option" priority>
            <input type="checkbox" id="priority-infoFilter" name="priority" value="high" class="mycheck circle" :checked="this.$route.query.priority === 'high'" />
            <label for="priority-infoFilter">Prioridad</label>
          </div>
        </label>
        <label for="siteRegister-infoFilter" class="item" @change="handleCheck($event)">
          <div role="option">
            <input
              type="checkbox"
              id="siteRegister-infoFilter"
              name="siteRegister"
              value="671ff9a2d596630895230d5f"
              class="mycheck circle"
              :checked="$route.query.siteRegister === '671ff9a2d596630895230d5f'" />
            <label for="siteRegister-infoFilter">Roa</label>
          </div>
        </label>
        <label for="satelite-infoFilter" class="item" @change="handleCheck($event)">
          <div role="option" satelite>
            <input type="checkbox" id="satelite-infoFilter" name="satelite" value="satelite" class="mycheck circle" :checked="this.$route.query.satelite === 'satelite'" />
            <label for="satelite-infoFilter">Satelite</label>
          </div>
        </label>
        <label for="videoModelFilter" class="item" @change="handleCheck($event)">
          <div role="option" video>
            <input type="checkbox" id="videoModelFilter" name="videoModel" value="videoModel" class="mycheck circle" :checked="$route.query.videoModel === 'videoModel'" />
            <label for="videoModelFilter">Video</label>
          </div>
        </label>
        <label for="gender-infoFilter" class="item" @change="handleCheck($event)">
          <div role="option" gender>
            <input type="checkbox" id="gender-infoFilter" name="gender" value="male" class="mycheck circle" :checked="$route.query.gender === 'male'" />
            <label for="gender-infoFilter">Masculino</label>
          </div>
        </label>
        <div class="item">
          <button @click="setFilter('reset'), initValues()">Quitar filtros</button>
        </div>
        <div class="item">
          <button @click="myPrint"><i class="fa fa-print" /></button>
        </div>
        <div class="item">
          <button @click="toExcel"><iconic name="excel" /></button>
        </div>
      </div>
    </div>
    <div class="section-models-posts-header-content options" v-if="filter == 'all'">
      <div class="wrp-btn">
        <a href="#" @click="setFilter({ srcElement: { name: 'mode', value: 'graphics' } })" :class="{ 'router-link-exact-active': linkInclude('&mode=graphics') }"> Gráficos </a>
      </div>
      <div class="wrp-btn">
        <a href="#" @click="setFilter({ srcElement: { name: 'mode', value: 'list' } })" :class="{ 'router-link-exact-active': linkInclude('&mode=list') }"> Registros </a>
      </div>
    </div>
    <v-md-date-range-picker ref="myDatePicker" @change="handleDate" :start-date="this.$route.query.dateFrom" :end-date="this.$route.query.dateTo"></v-md-date-range-picker>
  </div>
</template>

<script>
  import router from "../../router";
  import Swal from "sweetalert2";
  import Slider from "@vueform/slider/dist/slider.vue2.js";
  import global from "@/assets/js/global.js";
  import iconic from "@/plugins/iconic/iconic.vue";
  import alerts from "@/assets/js/swalAlerts";

  let timeout;
  export default {
    components: { Slider, iconic },
    data: function () {
      return {
        ageRange: [0, 100],
        ageChange: false,
        keyFilter: 0,
        isInterview: false,
        statusModel: "all",
        roleUser: "",
      };
    },
    props: {
      total: Number,
      filter: String,
    },
    computed: {
      getOptionStatus() {
        const values = [
          { value: "all", text: "Todas las modelos" },
          { value: "new", text: "Nuevas" },
          { value: "approved", text: "Aprobadas" },
          { value: "noassistence", text: "No asistió" },
          { value: "pending", text: "Pendientes" },
          { value: "disapproved", text: "No aprobadas" },
          { value: "selected", text: "Seleccionadas" },
          { value: "noselected", text: "No seleccionadas" },
          { value: "admitted", text: "Admitidas" },
          { value: "noadmitted", text: "No admitidas" },
          { value: "waiting", text: "En espera" },
          { value: "hired", text: "Contratadas" },
          { value: "deserter", text: "Desertoras" },
          { value: "waiver", text: "Renuncias" },
          { value: "endContract", text: "Cancelación contrato" },
        ];
        if (this.isInterview) {
          return [{ value: "all", text: "Todas las modelos" }, ...values.splice(2, 2)];
        }
        return values;
      },
    },
    methods: {
      myPrint: function () {
        const moreBtnSelector = ".page-admin-container-main .load-more-block .click-on-bottom";
        const moreContentBtn = document.querySelector(moreBtnSelector);
        const hasMoreContent = document.querySelector(".section-models-posts");
        const hasMore = hasMoreContent.getAttribute("data-hasmore");
        if (hasMore && moreContentBtn) {
          if (!Swal.isVisible()) {
            this.dataLoading();
          }
          moreContentBtn ? moreContentBtn.click() : false;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.myPrint();
          }, 500);
        } else {
          Swal.close();
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            window.print();
          }, 200);
        }
      },
      toExcel: async function () {
        let myRoute = this.$route.fullPath.split("/?");
        const _host = `${process.env.VUE_APP_API_HOST}admin/exports/?${myRoute[1]}`;
        const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
        this.dataLoading();
        let response = await fetch(_host, {
          headers: {
            [process.env.VUE_APP_TOKEN_NAME]: token,
          },
        });
        Swal.close();
        if (response.ok) {
          let file = await response.blob();
          const url = window.URL.createObjectURL(file, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" });
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "Registro_Modelos.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          const error = await response.json();
          alerts.dataSendError({ message: typeof error === "string" ? error : "No se pudo exportar los datos a un archivo excel." });
        }
      },
      linkIsActive(input) {
        return global.linkIsActive(this.$route, input);
      },
      linkInclude(input) {
        return global.linkInclude(this.$route, input);
      },
      dataLoading: function () {
        Swal.fire({
          title: "Cargando",
          text: "Se están cargando todos los datos para imprimir",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      },
      selectInterview: function (evt) {
        if (evt.target.value !== "all") {
          this.isInterview = true;
          if (this.$route.query.status) {
            const status = this.$route.query.status;
            this.statusModel = status === "approved" || status === "pending" ? status : "all";
          }
        } else {
          this.statusModel = "all";
          this.isInterview = false;
        }
        const query = this.$route.query;
        query.status = this.statusModel;
        router.push({ query }).catch(() => {});
      },
      setFilter: function (elmn) {
        let _push;
        if (elmn === "reset") {
          let mode = this.$route.query.mode;
          let filter = this.$route.query.filter;
          this.statusModel = "all";
          this.isInterview = false;
          this.keyFilter++;
          _push = { path: `/admin/models/?filter=${filter}${mode ? `&mode=${mode}` : ""}` };
        } else {
          let elm = elmn.srcElement;
          let query = this.$route.query;
          let nquery = { [elm.name]: elm.value };
          _push = { query: { ...query, ...nquery } };
        }
        router.push(_push).catch(() => {});
      },
      handleDate: function (data, values) {
        this.setFilter({ srcElement: { name: "dateFrom", value: values[0] } });
        this.setFilter({ srcElement: { name: "dateTo", value: values[1] } });
      },
      handleCheck: function ($event) {
        const myCheckbox = $event.srcElement;
        if (myCheckbox && myCheckbox.checked) {
          this.setFilter({ srcElement: { name: myCheckbox.name, value: myCheckbox.value } });
        } else if (myCheckbox) {
          this.setFilter({ srcElement: { name: myCheckbox.name, value: "" } });
        }
      },
      changeAge: function () {
        this.ageChange = true;
      },
      handleAge: function () {
        if (this.ageChange) {
          this.setFilter({ srcElement: { name: "ageFrom", value: this.ageRange[0] } });
          this.setFilter({ srcElement: { name: "ageTo", value: this.ageRange[1] } });
          this.ageChange = false;
        }
      },
      setCheck: function () {
        const isCheck = event.srcElement.checked ? true : false;
        if (isCheck === true) {
          this.setFilter({ srcElement: { name: event.srcElement.name, value: "on" } });
        } else {
          this.setFilter({ srcElement: { name: event.srcElement.name, value: "off" } });
        }
      },
      initValues: function () {
        const _this = this;
        let inputs = document.querySelectorAll(`.section-models-posts-header-filter [name]`);
        [].forEach.call(inputs, function (el) {
          let queryValue = _this.$route.query[el.name];
          if (queryValue) {
            el.value = queryValue;
          }
        });
        if (this.$route.query.ageFrom && this.$route.query.ageTo) {
          _this.ageRange = [this.$route.query.ageFrom, this.$route.query.ageTo];
        } else {
          _this.ageRange = [0, 100];
        }
        if (this.$route.query.status) this.statusModel = this.$route.query.status;
        if (this.$route.query.interview && this.$route.query.interview !== "all") this.isInterview = true;
      },
      formatDate(date) {
        const dateTransform = new Date(date);
        const day = String(dateTransform.getUTCDate()).padStart(2, "0");
        const month = String(dateTransform.getUTCMonth() + 1).padStart(2, "0");
        const year = String(dateTransform.getUTCFullYear()).substring(2);
        return `${day}/${month}/${year}`;
      },
    },
    mounted: async function () {
      const { role } = await this.$store.state.userProfile;
      this.roleUser = role;
      this.initValues();
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .section-models-posts-header {
    .router-link-exact-active:not(.nomark):not(.no-mark) {
      position: relative;
      color: $primary-color;
      font-size: bold;
      &::after {
        position: absolute;
        bottom: 0px;
        left: 4px;
        right: 4px;
        content: "";
        height: 3px;
        background-color: $primary_color;
        border-top-left-radius: 1px;
        border-top-right-radius: 1px;
      }
    }
    border-bottom: solid 1px $alto;
    .options {
      border-top: solid 1px $alto;
      .wrp-btn {
        width: 50%;
        a {
          cursor: pointer;
          padding: $mpadding $mpadding;
        }
      }
    }
    &-filter {
      overflow: hidden;
      overflow-x: auto;
      &-options {
        display: flex;
        white-space: nowrap;
        width: max-content;
        .item {
          float: left;
          display: flex;
          align-items: center;
          border-right: solid 1px $alto;
          user-select: none;
          &:first-child {
            background: #f1f3f4;
            button {
              cursor: text;
            }
          }
          &:hover {
            background: #f1f3f4;
          }
          .row {
            display: flex;
            align-items: center;
            padding: 0 $mpadding/2;
            margin-bottom: $mpadding/2;
            .check {
              display: flex;
              align-items: center;
              margin-right: $mpadding/2;
            }
          }
          &-sub {
            padding-top: $mpadding/2;
          }
          .label {
            margin: 0 $mpadding/2;
          }
          .slider {
            & + .option .apply {
              display: inline-block;
              min-width: 20px;
              text-align: center;
              padding: 2px 5px;
              border-radius: 1pc;
              color: $white;
              cursor: pointer;
              margin-right: 5px;
            }
          }
          .slider-tooltip,
          .option .apply,
          .slider-connect {
            background: $primary_color;
            border: 1px solid $primary_color;
          }
          [role="option"] {
            padding: 0 $mpadding;
          }
        }
        .mdrp__activator .activator-wrapper .text-field,
        button,
        select {
          font-size: 100%;
          padding: $mpadding/2 $mpadding;
          cursor: pointer;
          border: none;
          min-height: 100%;
          background: transparent;
          appearance: none;
          > i {
            font-size: 150%;
          }
        }
      }
    }
    .mdrp-root,
    .mdrp-root[data-v-7863e830] {
      position: absolute;
      top: $mpadding * 9;
      .mdrp__activator {
        display: none;
      }
      .calendar-table {
        &.left {
          padding: 8px;
        }
        &.right {
          display: none;
        }
      }
    }
    .slider-horizontal {
      width: 200px;
      margin: 0 $mpadding * 1.5 0 $mpadding/2;
      .slider-tooltip {
        border-radius: 1pc;
        bottom: -5px;
        &:before {
          opacity: 0;
        }
      }
    }
    @media (max-width: 768px) {
      margin-top: 70px;
    }
  }
</style>
