<template>
  <div class="models-comments" v-if="locale && comments">
    <div class="models-comments-comment" v-for="(comment, cmtindex) in comments" :key="cmtindex">
      <div class="models-comments-comment-header">
        <div class="models-comments-comment-header-user">
          <p bold dcolor capitalize>{{ comment.userName }}</p>
          <p bold fsmall v-if="comment.adminStatus">{{ locale.words[comment.adminStatus] }}</p>
        </div>
        <p fsmall>{{ $global.dateLocaleFormat(comment.date) }}</p>
      </div>
      <p class="models-comments-comment-text" v-html="decodeElement(comment.comment)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comments: null,
  },
  data: function () {
    return {
      locale: null,
    };
  },
  methods: {
    getdataLang: async function () {
      this.locale = await this.$store.getters["localeLang"];
    },
    decodeElement(elemnt = "") {
      try {
        return decodeURIComponent(elemnt);
      } catch (error) {
        console.log(error.message);
        return elemnt;
      }
    },
  },
  mounted: function () {
    this.getdataLang();
  },
};
</script>

<style lang="scss">
.models-comments {
  max-width: 500px;
  &-comment {
    width: 100%;
    padding: $mpadding/2;
    word-wrap: break-word;
    border-radius: $mradius;
    word-wrap: break-word;
    border: solid 1px $alto;
    background: $white;
    font-size: 14px;
    line-height: 18px;
    &:not(:last-child) {
      margin-bottom: $mpadding/2;
    }
    &-header {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
