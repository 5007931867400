import socketClass from "./socketClass.js";

class SocketBackend extends socketClass {
  constructor(user) {
    super();
    this.user = user;
  }

  /**
   * Metodo encargado de conectarse al backend de cada proyecto.
   * @returns socket
   */
  async connectToBackend() {
    try {
      const tokenName = process.env.VUE_APP_TOKEN_NAME;
      const token = localStorage.getItem(tokenName);
      const { role = "", _id = "", area = "", city = "", isSpecialFunctions = false } = this.user;
      const serverUrl = process.env.VUE_APP_SOCKET;
      const socketInfo = {
        path: "/api/v1/socket.io",
        auth: { [tokenName]: token },
        serverUrl,
        query: `role=${role}&_id=${_id}&area=${area}&city=${city}&isSpecialFunctions=${isSpecialFunctions}`,
      };
      await this.connect(socketInfo);

      return this.socket;
    } catch (error) {
      console.error(error.message);
    }
  }
}

export default SocketBackend;
