<template>
  <div class="models-info-charts chart-wrap">
    <div class="chart" v-if="this.$route.query.city === 'all' || !this.$route.query.city">
      <h2 class="chart-title">Ciudades</h2>
      <pie-chart :data="chartCitiesData" :library="library"></pie-chart>
    </div>
    <div class="chart" v-if="['waiver', 'endContract'].includes($route.query.status)">
      <h2 class="chart-title">{{ `Causa de la ${$route.query.status === "waiver" ? "renuncia" : "cancelación de contrato"}` }}</h2>
      <pie-chart :data="chartTypeData" :library="library"></pie-chart>
    </div>
    <div class="chart" v-if="this.$route.query.status === 'all' || !this.$route.query.status">
      <h2 class="chart-title">Estados</h2>
      <pie-chart :data="chartData" :library="library"></pie-chart>
    </div>
    <div class="chart" v-if="!['waiver', 'endContract'].includes($route.query.status)">
      <h2 class="chart-title">Horas pico de registros</h2>
      <area-chart :data="chartHours" :library="libraryArea"></area-chart>
    </div>
    <div
      class="chart"
      v-if="
        this.$route.query.status === 'all' ||
        this.$route.query.status === 'approved' ||
        this.$route.query.status === 'pending' ||
        !this.$route.query.status
      "
    >
      <h2 class="chart-title">Entrevistas ({{ totalInterviews }})</h2>
      <pie-chart :data="chartInterviews" :library="library"></pie-chart>
    </div>
  </div>
</template>

<script>
import store from "../../store/index.js";
export default {
  data: function () {
    return {
      locale: null,
      chartData: null,
      chartCitiesData: null,
      chartTypeData: null,
      chartHours: null,
      chartInterviews: null,
      totalInterviews: null,
      library: {
        is3D: true,
        pieSliceText: "value",
        legend: {
          position: "labeled",
          labeledValueText: "both",
          textStyle: {
            fontSize: 14,
          },
        },
        colors: ["#e62b26", "#50a2da", "#009e42", "#ec6667", "#f6c7b6", "#5e5e5e", "#9b9b9b"],
      },
      libraryArea: {
        discrete: true,
      },
    };
  },
  methods: {
    getdataLang: async function () {
      this.locale = await store.getters["localeLang"];
    },
    translateObj: function (obj) {
      const _this = this;
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (obj[key] === Object(obj[key])) {
          newObj[key] = _this.translateObj(obj[key]);
        } else if (obj[key] !== undefined) {
          newObj[_this.locale.words[key]] = obj[key];
        }
      });
      return newObj;
    },
    setInterviews: function (interviews) {
      this.totalInterviews = interviews.total;
      this.chartInterviews = interviews.statistics;
    },
  },
  mounted: async function () {
    this.$root.$on("newStatistics", async (data) => {
      await this.getdataLang();
      this.chartCitiesData = this.translateObj(data.statisticsCities);
      this.chartData = this.translateObj(data.statistics);
      this.chartHours = data.statisticsHours;
      this.chartTypeData = data.statisticsTypeDecision;
      this.setInterviews(data.statisticsInterviews);
    });
  },
};
</script>

<style lang="scss">
.models-info-charts {
  background: $white;
  .chart {
    overflow: hidden;
    border-bottom: solid 1px $alto;
    &-title {
      margin: $mpadding/2;
    }
    svg > g > g:last-child,
    svg > g > g:last-child circle,
    div.google-visualization-tooltip {
      pointer-events: none;
    }
  }
}
</style>
