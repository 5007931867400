<template>
  <ul class="record-info" v-if="this.post">
    <li v-html="`Estado <span dcolor>${locale.words[post.adminStatus]}</span>`"></li>
    <li>
      <span>Disponible</span>
      <span v-for="(tidx, time) in post.timeAvailability" :key="tidx">
        <b v-html="` ${locale.words[post.timeAvailability[time]]}`"></b>
      </span>
    </li>
    <li v-html="`<div>Fecha de Nacimiento</div><div bold>${dateFormat(post.birthDayDate)}</div>`"></li>

    <li v-if="setSocialNetwork.length" class="social">
      <SocialButtons
        v-for="(socialNetwork, i) in setSocialNetwork"
        :social-network="socialNetwork.socialNetwork"
        :username="socialNetwork.username"
        :key="i"
      />
    </li>
  </ul>
</template>

<script>
import global from "../../assets/js/global";
import SocialButtons from "../widgets/SocialButtons.vue";
export default {
  components: { SocialButtons },

  props: {
    post: Object,
    locale: Object,
  },
  data: function () {
    return {
      networksInfo: [
        {
          name: "",
        },
      ],
    };
  },
  computed: {
    setSocialNetwork() {
      const socialNetWorks = {
        facebookAccount: "facebook",
        instagramAccount: "instagram",
        tiktokAccount: "tiktok",
        onlyfansAccount: "onlyfans",
      };
      return Object.entries(this.post).reduce((acumulador, [key, value]) => {
        if (socialNetWorks[key] && value.trim()) {
          acumulador.push({
            socialNetwork: socialNetWorks[key],
            username: value,
          });
        }
        return acumulador;
      }, []);
    },
  },
  methods: {
    dateFormat: (date) => {
      return global.dateLocaleFormat(date);
    },
  },
};
</script>

<style lang="scss">
.record-info {
  li {
    margin-bottom: 5px;
  }
}
</style>
