<template>
  <div class="rate-field">
    <div class="sec-font">Valoración</div>
    <fieldset :readonly="readonly === true">
      <span class="star-cb-group" @change="checkRate()">
        <input type="radio" :id="`rating-5-${index}`" name="rating" value="5" :checked="current === 5" />
        <label :for="`rating-5-${index}`">5</label>
        <input type="radio" :id="`rating-4-${index}`" name="rating" value="4" :checked="current === 4" />
        <label :for="`rating-4-${index}`">4</label>
        <input type="radio" :id="`rating-3-${index}`" name="rating" value="3" :checked="current === 3" />
        <label :for="`rating-3-${index}`">3</label>
        <input type="radio" :id="`rating-2-${index}`" name="rating" value="2" :checked="current === 2" />
        <label :for="`rating-2-${index}`">2</label>
        <input type="radio" :id="`rating-1-${index}`" name="rating" value="1" :checked="current === 1" />
        <label :for="`rating-1-${index}`">1</label>
        <input type="radio" :id="`rating-0-${index}`" name="rating" value="0" class="star-cb-clear" />
        <label :for="`rating-0-${index}`">0</label>
      </span>
    </fieldset>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      myrate: null,
    };
  },
  props: {
    index: Number,
    current: Number,
    readonly: Boolean,
  },
  methods: {
    checkRate: function () {
      let rate = event.target.value;
      this.myrate = rate;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
$unchecked-star: "\2606";
$unchecked-color: #888;
$checked-star: "\2605";
$checked-color: #e52;
fieldset {
  position: relative;
  border: none;
  &[readonly] {
    pointer-events: none;
    &::after {
      content: "";
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .star-cb-group {
    /* remove inline-block whitespace */
    font-size: 0;
    * {
      font-size: 2rem;
    }
    /* flip the order so we can use the + and ~ combinators */
    unicode-bidi: bidi-override;
    direction: rtl;
    & > input {
      display: none;
      & + label {
        /* only enough room for the star */
        display: inline-block;
        overflow: hidden;
        text-indent: 9999px;
        width: 1em;
        white-space: nowrap;
        cursor: pointer;
        &:before {
          display: inline-block;
          text-indent: -9999px;
          content: $unchecked-star;
          color: $unchecked-color;
        }
      }
      &:checked ~ label:before,
      & + label:hover ~ label:before,
      & + label:hover:before {
        content: $checked-star;
        color: #e52;
        text-shadow: 0 0 1px #333;
      }
    }

    /* the hidden clearer */
    & > .star-cb-clear + label {
      text-indent: -9999px;
      width: 0.5em;
      margin-left: -0.5em;
    }
    & > .star-cb-clear + label:before {
      width: 0.5em;
    }

    &:hover > input + label:before {
      content: $unchecked-star;
      color: $unchecked-color;
      text-shadow: none;
    }
    &:hover > input + label:hover ~ label:before,
    &:hover > input + label:hover:before {
      content: $checked-star;
      color: $checked-color;
      text-shadow: 0 0 1px #333;
    }
  }
}
</style>
